.module {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 10px;
  padding-bottom: 0;
  background-color: #fff;
}

.item {
  display: flex;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  i {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 3px;
  }

  span {
    b {
      font-weight: 600;
    }
  }
}
