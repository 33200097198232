.module {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  margin-right: 20px;
}

.buttons {
  display: flex;
}

.button {
  &:not(:last-child) {
    margin-right: 10px;
  }
}
