.module {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 50px;
  }
}

.title {
  margin-bottom: 10px;
}

.floors {
  display: flex;
  flex-direction: column-reverse;
}
