.module {
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  background-color: #fff;
  overflow: hidden;
}

.image {
  position: relative;
  padding-bottom: 70%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.name {
  padding: 24px;
  color: #555;
}
