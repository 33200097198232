.module {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 2px;
}

.input {
  height: 32px;
  padding: 0 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}
