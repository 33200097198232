.module {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.townhouse {
    flex-grow: 1;
    width: 196px;

    &:not(:last-child) {
      // margin-right: 4px;
      border-right: 2px solid #000;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  height: 16px;
  padding-left: 5px;
  padding-right: 5px;

  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #000;
  }
}

// .top {
//   display: flex;
//   height: 25px;
//   border-bottom: 1px solid #fff;
// }

// .rooms {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 20%;
//   border-right: 1px solid #fff;
//   color: #fff;
// }

// .roomArea {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 40%;
//   border-right: 1px solid #fff;
//   color: #fff;
// }

// .roomPrice {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 40%;
//   color: #fff;
// }

// .center {
//   display: flex;
//   height: 25px;
//   border-bottom: 1px solid #fff;
// }

// .name {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 20%;
//   border-right: 1px solid #fff;
//   color: #fff;
// }

// .landArea {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 40%;
//   border-right: 1px solid #fff;
//   color: #fff;
// }

// .landPrice {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 40%;
//   color: #fff;
// }

// .bottom {
//   display: flex;
//   height: 24px;
// }

// .total {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   color: #fff;
// }
