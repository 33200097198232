.module {
  padding: 20px 20px 0;
  background-color: #fff;

  &.shrink {
    width: calc(100% - 500px);
  }
}

.title {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
}

.wrapper {
  display: flex;
}

.entrances {
  display: flex;
  padding-bottom: 40px;
  overflow-y: auto;
}
