.module {
  width: 320px;
  max-width: 100%;
  position: relative;
  padding: 60px 20px 20px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
}

.title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.login {
  margin-bottom: 20px;
}

.password {
  margin-bottom: 20px;
}

.submit {
  display: block;
  margin: 0 auto;
}
