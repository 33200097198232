.module {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 2px;
}

.select {
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
}
