.module {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.content {
  max-width: 100%;
  margin: 40px auto auto;
}
