.module {
  flex-basis: 1600px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &.townhouse {
    // flex-basis: auto;
    // flex-shrink: 1;

    flex-basis: 1600px;
    flex-shrink: 0;
  }
}

.title {
  margin-bottom: 10px;
}

.items {
  display: flex;
  flex-wrap: wrap;

  // border-top: 2px solid #000;
  border-left: 2px solid #000;
}
