.bg-44,
.bg-75 {
  background-color: #63cba5;
}

.bg-45,
.bg-76 {
  background-color: #a6a6a6;
}

.bg-46,
.bg-77 {
  background-color: #ffb400;
}

.bg-47,
.bg-78 {
  background-color: #555;
}

.bg-48,
.bg-79 {
  background-color: #048a4b;
}

.bg-49,
.bg-80 {
  background-color: #0ef;
}

.bg-50,
.bg-81 {
  background-color: #df57d8;
}

.bg-51,
.bg-82 {
  background-color: #c70a29;
}

.bg-52,
.bg-83 {
  background-color: #a08542;
}

.bg-without-pv {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #071ee9;
}
