.module {
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% / 11);
  // margin: 0 2px 4px;
  // font-size: 12px;

  border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;

  &:nth-child(n + 12) {
    border-top: none;
  }

  &.townhouse {
    // flex-basis: 100%;
    flex-basis: calc(100% / 11 * 10);
    max-width: calc(100% / 11 * 10);

    border-top: none;

    &:nth-child(2) {
      border-top: 2px solid #000;
    }
  }
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;

  font-size: 12px;
  font-weight: 600;

  border-bottom: 1px solid #000;
  background-color: #ccc;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;

  font-size: 12px;
  font-weight: 600;

  border-bottom: 1px solid #000;
  background-color: #ccc;
}

.items {
  display: flex;
}
