.module {
  width: 500px;
  max-width: 100%;
  position: relative;
  padding: 60px 20px 20px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
}

.title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.project {
  margin-bottom: 20px;
}

.info {
  // display: flex;
  display: none;
  justify-content: space-between;
  margin-bottom: 20px;

  strong {
    font-weight: 600;
  }
}

.prices {
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;

  label {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);

    cursor: pointer;
  }

  strong {
    margin-bottom: 8px;

    font-weight: 600;
  }

  p {
    display: flex;
    align-items: center;
  }

  input {
    margin: 0;
    margin-right: 10px;
  }
}

.status {
  margin-bottom: 20px;
}

.roomPrice {
  margin-bottom: 20px;
}

.landPrice {
  margin-bottom: 20px;
}

.comment {
  margin-bottom: 20px;
}

.certificates {
  padding-top: 20px;
}

.submit {
  display: block;
  margin-left: auto;
}
