// Font >>>
$font-family: 'Montserrat', sans-serif;
$font-size: 14px;
$font-weight: 400;
// Font <<<

// Colors >>>
$colors: (
  'primary': #4eaab5,
  'secondary': #f9d776,
  'accent': #000,
  'black': #000,
  'invalid': #000,
  'gold': #ffd98a,
  'goldDark': #d9ad65,
  'goldDarker': #a87529,
  'primaryBackground': #4eaab5,
);
// Colors <<<

// GRID >>>
$columns: 12;
$breakpoints: (
  '': 0,
  'sm': 576,
  'md': 768,
  'lg': 992,
  'xl': 1200,
);
$gutter-values: (
  '': 30,
  'sm': 30,
  'md': 30,
  'lg': 30,
  'xl': 30,
);
$container-values: (
  'sm': 540,
  'md': 720,
  'lg': 960,
  'xl': 1140,
);
// GRID <<<
