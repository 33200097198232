.module {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 120px;
  font-size: 12px;
  transition: 0.2s;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.top {
  display: flex;
  height: 25px;
  border-bottom: 1px solid #fff;
}

.type {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border-right: 1px solid #fff;
  color: #fff;
}

.area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  color: #fff;
}

.bottom {
  display: flex;
  height: 25px;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border-right: 1px solid #fff;
  color: #fff;
}

.price {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  color: #fff;
}

.withoutPV {
  box-shadow: inset 0px 0px 0px 2px #071ee9;
}

.blueMark {
  box-shadow: inset 0px 0px 0px 2px #3b67cd;
}

.redMark {
  box-shadow: inset 0px 0px 0px 2px #cd3b5b;
}

.orangeMark {
  box-shadow: inset 0px 0px 0px 2px #ff9f7f;
}
