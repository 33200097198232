.module {
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% / 11);
  min-width: calc(100% / 11);

  // border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;

  &:first-child {
    border-top: 2px solid #000;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 16px;
  padding-left: 5px;
  padding-right: 5px;

  font-size: 12px;

  &:nth-child(-n + 2) {
    font-weight: 600;
    background-color: #ccc;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #000;
  }
}
