.module {
  height: 32px;
  padding: 0 15px;
  border-radius: 4px;
  color: #fff;
  background-color: #40a9ff;
  transition: 0.2s;

  &:hover {
    background-color: #098af3;
  }
}
