.module {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  height: 100%;
  background-color: #fff;
  animation: widthGrow 0.2s;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  font-size: 20px;
  align-self: flex-end;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  border-top: 1px solid #ccc;
}

@keyframes widthGrow {
  from {
    width: 0;
  }

  to {
    width: 500px;
  }
}
