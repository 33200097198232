.module {
  padding: 24px;
}

.block {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  b {
    font-weight: 700;
  }
}

.comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  span {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    padding: 10px;
    border: 1px solid #ccc;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
}

.link3d {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 15px;
  border-radius: 4px;
  color: #fff;
  background-color: #40a9ff;
  transition: 0.2s;
  &:hover {
    background-color: #098af3;
  }
}
