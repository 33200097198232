.module {
  background-color: #001529;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.name {
  margin-right: 20px;
  color: #fff;
}
