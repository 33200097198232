.module {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 40px;
}

.floor {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 40px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
}
