.module {
  padding: 24px;
}

.block {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  b {
    font-weight: 700;
  }
}

.comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  span {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    padding: 10px;
    border: 1px solid #ccc;
  }
}

.actions {
  margin-bottom: 20px;
}

.project {
  margin-bottom: 20px;
}

.info {
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;

  p {
    display: flex;
    flex-direction: column;
  }

  strong {
    margin-bottom: 8px;

    font-weight: 600;
  }

  span {
    margin-bottom: 3px;
  }

  i {
    font-style: italic;
    text-decoration: underline;
    margin-bottom: 3px;
  }
}
