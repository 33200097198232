.module {
  display: flex;
  flex-direction: column;
}

.name {
  margin-bottom: 20px;
  font-size: 21px;

  span {
    margin-right: 20px;
    font-weight: 600;
  }

  b {
    font-weight: 700;
  }
}
