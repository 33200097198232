.module {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 2px;
}

.textarea {
  height: 100px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
}
