.module {
  width: 500px;
  max-width: 100%;
  position: relative;
  padding: 60px 20px 20px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
}

.title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.status {
  margin-bottom: 20px;
}

.roomPrice {
  margin-bottom: 20px;
}

.landPrice {
  margin-bottom: 20px;
}

.comment {
  margin-bottom: 20px;
}

.certificates {
  padding-top: 20px;
}

.submit {
  display: block;
  margin-left: auto;
}
